import React from 'react'
import { graphql } from 'gatsby'
import HeaderSEO from '../../components/partials/HeaderSEO'
import get from 'lodash/get'

import Layout from '../../components/Layout'
import VersionList from '../../components/VersionsList'

class twoPointTwo extends React.Component {
  render() {
    const pageSEO = get(this, 'props.data.allContentfulPages.edges[0].node')

    return (
      <Layout>
        <HeaderSEO pageSEO={pageSEO} />

        <section className="my-5 py-5 bg-gray">
          <h1 className="text-center my-4">
            Third Party Software - Lenses 2.2
          </h1>
        </section>

        <section className="my-5 py-5">
          <div className="container-1">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <b>Please choose the version of lenses: </b>

                <VersionList />

                <h4 className="underline">Third Party Libraries : Lenses</h4>

                <p>
                  The table below lists third party software that is
                  incorporated into <b>Lenses</b>, along with the license
                  associated with the software.
                </p>

                <table className="table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="entry w-40 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        JVM Project
                      </th>
                      <th
                        className="entry w-60 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">akka/akka-actor</td>
                      <td className="text-center">
                        <a
                          href="https://github.com/akka/akka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">akka/akka-parsing</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka-http"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/avro</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/avro"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/avatica-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite-avatica"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/calcite-linq4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/calcite-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/avatica-metrics</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/calcite-avatica"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/apacheds-i18n</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/directory-server"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        apache/apacheds-kerberos-codec
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/directory-server"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/api-asn1-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/directory-server"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-cli</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-cli"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-codec</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-codec"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-compress</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-compress"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-io</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-lang</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-lang"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-lang3</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-lang"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-logging</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-logging"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-net</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-net"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/commons-pool</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/commons-pool"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/curator-framework</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/curator"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/curator-client</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/curator"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/curator-recipes</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/curator"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpclient</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpcomponents-client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpcore</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpcomponents-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/zookeeper</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/zookeeper"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpasyncclient</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpasyncclient"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">apache/httpcore-nio</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/httpcomponents-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        apiguardian-team/apiguardian-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apiguardian-team/apiguardian"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">arrow-kt/arrow-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/arrow-kt/arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        arrow-kt/arrow-annotations
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/arrow-kt/arrow"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">b_c/jose4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://bitbucket.org/b_c/jose4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">circe/circe-generic</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        circe/circe-generic-extras
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">circe/circe-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">circe/circe-numbers</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">circe/circe-parser</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">circe/circe-jawn</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/circe/circe"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">confluent/common-config</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/common"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">confluent/common-utils</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/common"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-avro-serializer
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-connect-avro-converter
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-schema-registry-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        confluent/kafka-schema-registry
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/confluentinc/schema-registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">codehaus/jackson</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/codehaus/jackson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">codehaus/jackson-core-asl</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/codehaus/jackson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        codehaus/jackson-mapper-asl
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/codehaus/jackson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">chuusai/shapeless_2</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/milessabin/shapeless"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">dropwizard/metrics-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/dropwizard/metrics"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">esri/esri-geometry-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Esri/geometry-api-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        elasticsearch/elasticsearch-rest-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/elastic/elasticsearch/tree/master/client/rest/src/main/java/org/elasticsearch/client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fabric8io/kubernetes-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fabric8io/kubernetes-client"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fabric8io/kubernetes-model
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fabric8io/kubernetes-model"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">fabric8io/zjsonpatch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fabric8io/zjsonpatch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-annotations
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-annotations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">fasterxml/jackson-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-databind
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-databind"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-dataformat-yaml
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-dataformats-text"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-module-paranamer
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-modules-base"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-module-scala
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-module-scala"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-module-jaxb-annotations
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-module-jaxb-annotations"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">fasterxml/woodstox-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/woodstox"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-jaxrs-json-provider
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-jaxrs-providers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-jaxrs-base
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-jaxrs-providers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        fasterxml/jackson-dataformat-xml
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/jackson-dataformat-xml"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/gson</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/gson"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/guava</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/guava"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/jsr305</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://code.google.com/p/jsr-305"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        hortonworks/schema-registry-serdes
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hortonworks/registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        hortonworks/schema-registry-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hortonworks/registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">hortonworks/common-auth</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hortonworks/registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        hortonworks/registry-common-client
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hortonworks/registry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        hortonworks/schema-registry-common
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hortonworks/registry/tree/master/schema-registry/common"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">h2database/h2</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/h2database/h2database"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jetbrains/annotations</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JetBrains/kotlin/tree/master/libraries/tools/kotlin-annotations-jvm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        jetbrains/kotlin-stdlib-common
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib/common"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        jetbrains/kotlin-stdlib-jdk7
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JetBrains/kotlin/tree/master/libraries/stdlib/jdk7"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jodaorg/joda-time</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JodaOrg/joda-time"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-ast</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s-ast"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-jackson</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-native</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">json4s/json4s-scalap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/json4s/json4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/kafka</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/connect-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/connect-json</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/kafka-clients</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kafka/kafka-streams</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/apache/kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">kindedj/kindedj</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/KindedJ/KindedJ"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">lz4/lz4-java</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lz4/lz4-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">mifmif/generex</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/mifmif/Generex"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">megard/akka-http-cors</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lomigmegard/akka-http-cors"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">netty/netty</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/netty/netty"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">nscala-time/nscala-time</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/nscala-time/nscala-time"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">org.yaml/snakeyaml</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://bitbucket.org/asomov/snakeyaml"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ota4j-team/opentest4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ota4j-team/opentest4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala-reflect</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala/tree/2.13.x/src/reflect"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala-compiler</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala/tree/2.13.x/src/compiler/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala-java8-compat</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-java8-compat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        scala/scala-parser-combinators
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-parser-combinators"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">scala/scala-xml</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/scala/scala-xml"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        spring-projects/spring-ldap-core
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-ldap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        spring-projects/spring-beans
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-framework"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        spring-projects/spring-core
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-framework"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">spring-projects/spring-tx</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spring-projects/spring-framework"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">snappy/snappy-java</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/xerial/snappy-java"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">spray/spray-json</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/spray/spray-json"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        squareup.okhttp3/logging-interceptor
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/square/okhttp/tree/master/okhttp-logging-interceptor"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        swagger-akka/swagger-akka-http
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/swagger-akka-http/swagger-akka-http"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">swagger/swagger-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/swagger-api/swagger-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">swagger/swagger-jaxrs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/swagger-api/swagger-core/wiki/Swagger-Core-JAX-RS-Project-Setup-1.5.X"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">swagger/swagger-models</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/swagger-api/swagger-core/tree/master/modules/swagger-models"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        swagger/swagger-scala-module
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/swagger-api/swagger-scala-module"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">swagger/okhttp</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/square/okhttp"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">swagger/okio</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/square/okio"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/akka-http</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka-http"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/akka-http-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka-http"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/akka-stream</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/akka-slf4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        typesafe/akka-http-spray-json
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://doc.akka.io/docs/akka-http/current/common/json-support.html"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/akka-protobuf</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/akka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        typesafe/akka-stream-kafka
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/akka/reactive-kafka"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/config</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lightbend/config"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/scala-logging</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lightbend/scala-logging"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typesafe/ssl-config-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/lightbend/ssl-config"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/macro-compat</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/milessabin/macro-compat"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        univocity/univocity-parsers
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/uniVocity/univocity-parsers"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        yahoo.datasketches/sketches-core
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/DataSketches/sketches-core"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">yahoo.datasketches/memory</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/DataSketches/memory"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sgroschupf/zkclient</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sgroschupf/zkclient"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">org.tukaani/xz</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://git.tukaani.org/?p=xz-java.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Public
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/cats-kerne</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typelevel/cats"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/cats-macros</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typelevel/cats"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/machinist</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typelevel/machinist"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">pureconfig/pureconfig</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pureconfig/pureconfig"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        pureconfig/pureconfig-macros
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pureconfig/pureconfig"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">github/classgraph</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/classgraph/classgraph"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">spire-math/jawn-parser</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://github.com/non/jawn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/exts</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/exts"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/avro4s-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/avro4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/mallet</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/avro4s-macros</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/avro4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/elastic4s-http</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/elastic4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">sksamuel/elastic4s-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/sksamuel/elastic4s"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">typelevel/cats-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/typelevel/cats"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">seratch/jslack</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/seratch/jslack"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">nycto/hasher</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Nycto/Hasher"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">slf4j/slf4j-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/slf4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">slf4j/jul-to-slf4j</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/slf4j/tree/master/jul-to-slf4j"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        unboundid/unboundid-ldapsdk
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pingidentity/ldapsdk"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          GPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">reflections/reflections</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ronmamo/reflections"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          WTFPL
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        codehaus.woodstox/stax2-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FasterXML/stax2-api"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">brics/automaton</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/cs-au-dk/dk.brics.automaton"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">thoughtworks/paranamer</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/paul-hammant/paranamer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">google/protobuf</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/google/protobuf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        dk.brics.automaton/automaton
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/cs-au-dk/dk.brics.automaton"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">rocksdb/rocksdbjni</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/fusesource/rocksdbjni"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0, GPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jboss-javassist/javassist</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jboss-javassist/javassist"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          APL, MPL, LGPL
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">javax.ws/rs-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jax-rs/api"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: CDDL, GPL1.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">javax/validation-api</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/beanvalidation/beanvalidation-api"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">logback/logback-classic</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/logback"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: EPL1, LGPL2.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">logback/logback-core</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/qos-ch/logback"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Dual: EPL1, LGPL2.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        junit/junit-platform-commons
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/junit-team/junit5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        junit/junit-platform-engine
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/junit-team/junit5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        junit/junit-platform-launcher
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/junit-team/junit5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        junit/junit-platform-suite-api
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/junit-team/junit5"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          EPL 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        reactive-streams/reactive-streams
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reactive-streams/reactive-streams-jvm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Create Commons CC0
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p className="my-4">
                  The table below lists third party software that is
                  incorporated into <b>Lenses UI</b>, along with the license
                  associated with the software.
                </p>

                <table className="w-100 table-striped table-hover">
                  <thead className="thead">
                    <tr>
                      <th
                        className="entry w-40 text-center"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        JS Project
                      </th>
                      <th
                        className="entry text-center w-60"
                        style={{ backgroundColor: '#344a64', color: 'white' }}
                      >
                        License
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-center">angular</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-animate</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-aria</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-base64</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ninjatronic/angular-base64"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-chart.js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jtblin/angular-chart.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-diff-match-patch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/amweiss/angular-diff-match-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-json-tree</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/awendland/angular-json-tree"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Creative Commons
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-moment-picker</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://indrimuska.github.io/angular-moment-picker"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-google-chart</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-google-chart/angular-google-chart"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-route</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-sanitize</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular/angular.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-ui-bootstrap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-ui/bootstrap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-utils-pagination</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/michaelbromley/angularUtils-pagination"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angular-visjs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/visjs/angular-visjs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">angularjs-slider</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-slider/angularjs-slider"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">avro-js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://svn.apache.org/repos/asf/avro/trunk/lang/js/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">axios</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/axios/axios"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">axios-retry</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/softonic/axios-retry"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">babel-polyfill</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/babel/babel/tree/master/packages/babel-polyfill"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">bootstrap</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/twbs/bootstrap"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">classnames</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JedWatson/classnames"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">codemirror</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/codemirror/CodeMirror/blob/master/LICENSE"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">diff-match-patch</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://code.google.com/archive/p/google-diff-match-patch"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">event-source-polyfill</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Yaffle/EventSource.git"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">file-saver</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/eligrey/FileSaver.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">font-awesome</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://fontawesome.io"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          OFL 1.1
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">formik</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jaredpalmer/formik"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">handsontable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/handsontable/handsontable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">humanize-duration</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/EvanHahn/HumanizeDuration.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Public
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jquery</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jquery/jquery"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jsonlint-mod</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://github.com/circlecell/jsonlint"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jsonlint-mod</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="http://github.com/circlecell/jsonlint"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Stuk/jszip"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">jszip-utils</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/Stuk/jszip-utils"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">linkifyjs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/SoapBox/linkifyjs"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">memoize-one</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/alexreardon/memoize-one"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">moment</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/moment/moment"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-handsontable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/handsontable/ngHandsontable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-redux</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/angular-redux/ng-redux"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ng-redux-router</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/neilff/redux-ui-router"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ngletteravatar</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/uttesh/ngletteravatar"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">popper.js</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/FezVrasta/popper.js"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">prop-types</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/facebook/prop-types"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">ramda</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ramda/ramda"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/facebook/react"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        react-bootstrap-table-next
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/react-bootstrap-table/react-bootstrap-table2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        react-bootstrap-table2-paginator
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/react-bootstrap-table/react-bootstrap-table2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        react-bootstrap-table2-toolkit
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/react-bootstrap-table/react-bootstrap-table2"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-dom</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/facebook/react"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-input-autosize</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JedWatson/react-input-autosize"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-redux</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reduxjs/react-redux"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-responsive-modal</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/pradel/react-responsive-modal"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-router-dom</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ReactTraining/react-router"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-select</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/JedWatson/react-select"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-tagsinput</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/olahol/react-tagsinput"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react-user-avatar</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/wbinnssmith/react-user-avatar"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">react2angular</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/coatue-oss/react2angular"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">recompose</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/acdlite/recompose"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reactjs/redux"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-action-buffer</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rt2zz/redux-action-buffer"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-logger</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/evgenyrodionov/redux-logger"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-observable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/redux-observable/redux-observable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-persist</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rt2zz/redux-persist"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">
                        redux-persist-seamless-immutable
                      </td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/hilkeheremans/redux-persist-seamless-immutable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">redux-seamless-immutable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/eadmundo/redux-seamless-immutable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">reduxsauce</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/infinitered/reduxsauce"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">reselect</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/reduxjs/reselect"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">rxjs</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/ReactiveX/RxJS"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">seamless-immutable</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/rtfeldman/seamless-immutable"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          BSD-3
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">underscore</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jashkenas/underscore"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">universal-router</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/kriasoft/universal-router"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          MIT
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">vis</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/almende/vis"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-center">yup</td>
                      <td className="text-center">
                        {' '}
                        <a
                          href="https://github.com/jquense/yup"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Apache 2.0
                        </a>{' '}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container-1">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <h4>Website Icons</h4>
                from{' '}
                <a href="https://www.flaticon.com/" title="Flaticon">
                  www.flaticon.com{' '}
                </a>
                is licensed by{' '}
                <a
                  href="http://creativecommons.org/licenses/by/3.0/"
                  title="Creative Commons BY 3.0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CC 3.0 BY
                </a>
                <ul>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/smashicons"
                        title="Smashicons"
                      >
                        Smashicons
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/eucalyp"
                        title="Eucalyp"
                      >
                        Eucalyp
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/pixel-buddha"
                        title="Pixel Buddha"
                      >
                        Pixel Buddha
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/monkik"
                        title="monkik"
                      >
                        monkik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a href="http://www.freepik.com" title="Freepik">
                        Freepik
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/surang"
                        title="surang"
                      >
                        surang
                      </a>
                    </div>
                  </li>
                  <li>
                    <div>
                      Icons made by{' '}
                      <a
                        href="https://www.flaticon.com/authors/geotatah"
                        title="geotatah"
                      >
                        geotatah
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default twoPointTwo

export const pageQuery = graphql`
  query ThirdPartySofware22SEO {
    allContentfulPages(
      filter: {
        pageReference: { pageIdentifier: { eq: "Third_Party_Software_2.2" } }
      }
    ) {
      edges {
        node {
          title {
            title
          }
          keywords {
            keywords
          }
          description {
            description
          }
          oGimage {
            fluid(quality: 100) {
              src
            }
          }
        }
      }
    }
  }
`
